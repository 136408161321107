import clsx from 'clsx';
import { forwardRef } from 'react';

import LoadingSpinner from './LoadingSpinner';

import type { HeroIcon } from '~/types';
import type { ButtonHTMLAttributes } from 'react';

export type ButtonProps = {
  Icon?: HeroIcon;
  RightIcon?: HeroIcon;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  iconClassName?: string;
  loading?: boolean;
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
  variant?: 'none' | 'primary' | 'secondary' | 'icon';
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = 'secondary',
      size = variant === 'icon' ? 'md' : 'sm',
      disabled = false,
      loading = false,
      checked = false,
      type = 'button',
      children,
      className,
      iconClassName,
      Icon,
      RightIcon,
      ...rest
    }: ButtonProps,
    ref,
  ) => (
    <button
      ref={ref}
      {...rest}
      disabled={disabled || loading}
      type={type}
      className={clsx(
        'flex items-center justify-center gap-2 transition focus:outline-none',
        'leading-normal disabled:cursor-not-allowed',
        {
          'disabled:border-none disabled:bg-neutral-200 disabled:text-neutral-400':
            variant !== 'none',
          'rounded-md px-3 py-2.5 font-normal active:bg-neutral-500 active:text-neutral-50':
            variant === 'primary' || variant === 'secondary',
          'border border-neutral-550 bg-primary-600 text-neutral-50 hover:bg-neutral-550':
            variant === 'primary',
          'border border-neutral-250 bg-neutral-50 text-primary-500 hover:bg-neutral-200':
            variant === 'secondary',
          'rounded-md border border-neutral-250 bg-neutral-50 hover:scale-105 hover:text-accent-purple-400 hover:shadow-drop active:bg-neutral-300':
            variant === 'icon',
          'text-[0.625rem]': size === 'xxs',
          'text-sm': size === 'sm' || size === 'xs',
          'text-base': size === 'md',
          'text-lg': size === 'lg',
          'px-1.5 py-[0.25rem]': size === 'xs' || size === 'xxs',
          'p-1': size === 'sm' && variant === 'icon',
          'p-1.5': size === 'md' && variant === 'icon',
          'p-2.5': size === 'lg' && variant === 'icon',
        },
        className,
      )}
    >
      {loading && (
        <LoadingSpinner
          animation={{
            initial: { width: 0 },
            animate: { width: 16 },
            transition: {
              duration: 0.5,
            },
            exit: { width: 0 },
          }}
          className="h-4 w-4 !p-0"
          svgClassName="!m-0"
        />
      )}

      {Icon && (
        <Icon
          className={clsx(
            {
              'h-2 w-2': size === 'xxs',
              'h-3 w-3': size === 'sm' || size === 'xs',
              'h-4 w-4': size === 'md' || size === 'lg',
            },
            iconClassName,
          )}
        />
      )}

      {children}

      {RightIcon && (
        <RightIcon
          className={clsx(
            {
              'h-2 w-2': size === 'xxs',
              'h-4 w-4': size === 'sm' || size === 'xs',
              'h-5 w-5': size === 'md' || size === 'lg',
            },
            iconClassName,
          )}
        />
      )}
    </button>
  ),
);

Button.displayName = 'Button';

export default Button;
